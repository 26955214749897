/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createPeriod } from '../fn/explore-graph/create-period';
import { CreatePeriod$Params } from '../fn/explore-graph/create-period';
import { getNodes } from '../fn/explore-graph/get-nodes';
import { GetNodes$Params } from '../fn/explore-graph/get-nodes';
import { Graph } from '../models/graph';
import { listPeriods } from '../fn/explore-graph/list-periods';
import { ListPeriods$Params } from '../fn/explore-graph/list-periods';
import { PeriodResponse } from '../models/period-response';
import { PeriodResponsePage } from '../models/period-response-page';
import { updatePeriod } from '../fn/explore-graph/update-period';
import { UpdatePeriod$Params } from '../fn/explore-graph/update-period';

@Injectable({ providedIn: 'root' })
export class ExploreGraphApi extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `listPeriods()` */
  static readonly ListPeriodsPath = '/v0/explore-graph/periods';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `listPeriods()` instead.
   *
   * This method doesn't expect any request body.
   */
  listPeriods$Response(params?: ListPeriods$Params, context?: HttpContext): Observable<StrictHttpResponse<PeriodResponsePage>> {
    return listPeriods(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `listPeriods$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  listPeriods(params?: ListPeriods$Params, context?: HttpContext): Observable<PeriodResponsePage> {
    return this.listPeriods$Response(params, context).pipe(
      map((r: StrictHttpResponse<PeriodResponsePage>): PeriodResponsePage => r.body)
    );
  }

  /** Path part for operation `createPeriod()` */
  static readonly CreatePeriodPath = '/v0/explore-graph/periods';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPeriod()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPeriod$Response(params: CreatePeriod$Params, context?: HttpContext): Observable<StrictHttpResponse<PeriodResponse>> {
    return createPeriod(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPeriod$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPeriod(params: CreatePeriod$Params, context?: HttpContext): Observable<PeriodResponse> {
    return this.createPeriod$Response(params, context).pipe(
      map((r: StrictHttpResponse<PeriodResponse>): PeriodResponse => r.body)
    );
  }

  /** Path part for operation `getNodes()` */
  static readonly GetNodesPath = '/v0/explore-graph/periods/{periodId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNodes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNodes$Response(params: GetNodes$Params, context?: HttpContext): Observable<StrictHttpResponse<Graph>> {
    return getNodes(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNodes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNodes(params: GetNodes$Params, context?: HttpContext): Observable<Graph> {
    return this.getNodes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Graph>): Graph => r.body)
    );
  }

  /** Path part for operation `updatePeriod()` */
  static readonly UpdatePeriodPath = '/v0/explore-graph/periods/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePeriod()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePeriod$Response(params: UpdatePeriod$Params, context?: HttpContext): Observable<StrictHttpResponse<PeriodResponse>> {
    return updatePeriod(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePeriod$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updatePeriod(params: UpdatePeriod$Params, context?: HttpContext): Observable<PeriodResponse> {
    return this.updatePeriod$Response(params, context).pipe(
      map((r: StrictHttpResponse<PeriodResponse>): PeriodResponse => r.body)
    );
  }

}
