import { inject } from '@angular/core';
import { UsersApi } from './api/backend-connector/services';
import { firstValueFrom } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'welcome/user-onboard',
    loadComponent: () => import('./pages/user-onboard-page/user-onboard-page.component'),
  },
  {
    path: 'welcome',
    loadComponent: () => import('./pages/welcome-page/welcome-page.component'),
  },
  {
    path: '',
    loadComponent: () => import('./pages/main/main.component').then((m) => m.MainComponent),
    canActivate: [
      async () => {
        const router = inject(Router);
        const usersApi = inject(UsersApi);

        try {
          const me = await firstValueFrom(usersApi.getMe());
          if (me.firstName) {
            return true;
          }

          return router.createUrlTree(['welcome', 'user-onboard']);
        } catch (e) {
          if (e instanceof HttpErrorResponse && e.status == 401) {
            return router.createUrlTree(['welcome']);
          }

          throw e;
        }
      },
    ],
    children: [
      {
        path: 'not-found',
        loadComponent: () => import('./pages/not-found-page/not-found-page.component'),
      },
      {
        path: 'my-articles',
        loadComponent: () => import('./pages/my-articles-page/my-articles-page.component'),
      },
      {
        path: 'coeditor/:id',
        loadComponent: () => import('./pages/cowrite-page/cowrite-page.component'),
      },
      {
        path: 'coeditor',
        loadComponent: () => import('./pages/cowrite-page/cowrite-page.component'),
      },
      {
        path: 'news/:id',
        loadComponent: () => import('./pages/topic-details-page/topic-details-page.component'),
      },
      {
        path: 'news',
        loadComponent: () => import('./pages/topics-list-page/topics-list-page.component'),
      },
      {
        path: 'news/articles/:id',
        loadComponent: () => import('./pages/article-details-page/article-details-page.component'),
      },
      {
        path: 'explore',
        loadComponent: () => import('./pages/explore-page/explore-page.component'),
      },
      {
        path: 'policies',
        loadComponent: () => import('./pages/policies-page/policies-page.component'),
      },
      {
        path: 'policies/privacy-policy',
        loadComponent: () => import('./pages/policies-page/privacy-policy/privacy-policy.component'),
      },
      {
        path: 'policies/cookie-policy',
        loadComponent: () => import('./pages/policies-page/cookie-policy/cookie-policy.component'),
      },
      {
        path: 'policies/terms-conditions',
        loadComponent: () => import('./pages/policies-page/terms-conditions/terms-conditions.component'),
      },
      {
        path: '',
        redirectTo: 'explore',
        pathMatch: 'full',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '',
  },
];
