import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, switchMap } from 'rxjs';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  readonly authService = inject(OidcSecurityService);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!ApiInterceptor.isApiRequest(req)) {
      return next.handle(req);
    }

    return this.authService.checkAuth().pipe(
      switchMap(({ idToken }) =>
        next.handle(
          req.clone({
            withCredentials: true,
            setHeaders: idToken
              ? {
                  Authorization: `Bearer ${idToken}`,
                }
              : {},
          }),
        ),
      ),
    );
  }

  private static isApiRequest = (request: HttpRequest<unknown>) =>
    request.url.startsWith(`${window.location.origin}/api`) || request.url.startsWith('/api');
}
