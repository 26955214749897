export const environment = {
  apiBasePath: '/api',
  auth: {
    clientId: '808286372885-7bd4a2ihj33n6okrjg73jmu981bnnn2p.apps.googleusercontent.com',
    clientSecret: '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',
  },
  defaultLang: 'it',
  production: true,
  appRevision: import.meta.env?.NG_APP_REVISION,
  appVersion: import.meta.env?.NG_APP_VERSION ?? 'development',
};
