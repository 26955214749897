import { Injectable } from '@angular/core';

import { D3Graph, D3Node } from '@swimlane/ngx-graph';
import { Graph, ArticleNode, TopicNode } from '../api/backend-connector/models';
import { CustomNode, ARTICLE, TOPIC, LINKLESS_ARTICLE, CategoryNode, filterColors, CATEGORY } from '../utils/graph';

interface D3ProcessedEdge {
  source: D3Node;
  target: D3Node;
  isCategory: boolean;
}

type LayedOutGraph = {
  nodes: (CustomNode & D3Node)[];
  edges: D3ProcessedEdge[];
} & D3Graph;

@Injectable({
  providedIn: 'root',
})
export class GraphLayoutService {
  private readonly worker = new Worker(new URL('./graph-layout.worker', import.meta.url));

  setGraphData(graph: Graph) {
    const result = new Promise<LayedOutGraph>((resolve) => {
      this.worker.onmessage = (event) => {
        console.log(event);
        resolve({
          nodes: event.data.nodes,
          edges: event.data.links,
        });
      };
    });

    const nodes: CustomNode[] = [
      ...graph.articles.map(
        (n) =>
          ({
            label: n.title,
            color: n.relatedTo.length ? ARTICLE : LINKLESS_ARTICLE,
            ...n,
            category: n.data?.['category'],
            type: 'article',
          }) as ArticleNode & { type: 'article'; label: string; color: string },
      ),
      ...graph.topics.map(
        (n) =>
          ({
            label: n.title,
            color: TOPIC,
            ...n,
            category: n.data?.['category'],
            type: 'topic',
          }) as TopicNode & { type: 'topic'; label: string; color: string },
      ),
    ];

    const links = graph.articles.flatMap((a) =>
      a.relatedTo.map((t) => ({
        source: a.id,
        target: t.id,
        isCategory: false,
      })),
    );

    const nodesToCategorize = [
      ...graph.topics.filter((t) => t.data['category']),
      ...graph.articles.filter((a) => a.data['category'] && !a.relatedTo.length),
    ];
    const categories = this.getCategoryNodes(nodesToCategorize);
    const categoryMap = new Map<string, number>();
    graph.topics.forEach((t) => {
      const nodesCount = categoryMap.get(t.data['category']) ?? 0;
      categoryMap.set(t.data['category'], nodesCount + t.articlesCount + 1);
    });
    const categoryList = Array(...categories.values())
      .map((c) => ({
        ...c,
        nodesCount: categoryMap.get(c.id) ?? 0,
      }))
      .sort((a, b) => b.nodesCount - a.nodesCount)
      .map((c, i) => ({ ...c, color: i < 6 ? filterColors[i] : CATEGORY }));

    nodes.push(...categoryList);

    const categoryLinks = nodesToCategorize.map((n) => ({
      source: n.id,
      target: categories.get(n.data['category'])!.id,
      isCategory: true,
    }));
    links.push(...categoryLinks);

    this.worker.postMessage({
      nodes,
      links,
    });

    return result;
  }

  private getCategoryNodes(nodesToCategorize: Array<ArticleNode | TopicNode>) {
    const uniqueCategories = [
      ...nodesToCategorize.filter((n) => !!n.data['category']).map((n) => n.data['category'] as string),
    ];
    return new Map(uniqueCategories.map((c) => [c, this.generateCategoryNode(c)]));
  }

  private generateCategoryNode(name: string): CategoryNode {
    return {
      id: name,
      title: name,
      label: name,
      type: 'category',
      color: null,
      nodesCount: 0,
    };
  }
}
