/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { TopicWithArticles } from '../../models/topic-with-articles';

export interface ListRelatedTopics$Params {
  id: string;
  periodId?: string;
  articleLimit?: number;
  sorting?: Array<'id' | '-id' | 'name' | '-name' | 'enrichmentState' | '-enrichmentState' | 'title' | '-title' | 'shortSummary' | '-shortSummary' | 'longSummary' | '-longSummary' | 'date' | '-date' | 'data' | '-data' | 'periodId' | '-periodId' | 'articlesCount' | '-articlesCount' | 'createdAt' | '-createdAt' | 'updatedAt' | '-updatedAt'>;
}

export function listRelatedTopics(http: HttpClient, rootUrl: string, params: ListRelatedTopics$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TopicWithArticles>>> {
  const rb = new RequestBuilder(rootUrl, listRelatedTopics.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {"style":"simple"});
    rb.query('periodId', params.periodId, {"style":"form"});
    rb.query('articleLimit', params.articleLimit, {"style":"form"});
    rb.query('sorting', params.sorting, {"style":"form"});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<TopicWithArticles>>;
    })
  );
}

listRelatedTopics.PATH = '/v0/articles/{id}/related-topics';
